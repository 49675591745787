.login {
  padding: 120px 0;

  h2 {
    color: #000000;
    font-size: 30px;
    font-weight: 400;
    line-height: 55px;
    letter-spacing: -0.57px;
    text-align: center;
    width: 100%;
    padding: 0 0 50px;
    margin: 0;
  }

  h3 {
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.38px;
    padding-bottom: 23px;
    margin: 0;
  }

  &__text {
    color: #999999;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.28px;
  }

  form {
    input[type="email"],
    input[type="password"] {
      background-color: $gray-light;
      outline: none;
      color: #999999;
      font-size: 15px;
      font-weight: 400;
      line-height: 51px;
      letter-spacing: -0.28px;
      height: 51px;
      padding: 0 10px;
      margin-bottom: 8px;
      width: 100%;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
    }

    button {
      color: #ffffff;
      font-size: 11px;
      font-weight: 400;
      line-height: 53px;
      text-transform: uppercase;
      letter-spacing: 1.1px;
      background-color: $blue;
      height: 50px;
      text-align: center;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 0 55px;
      border: 1px #9ed2ee solid;
      outline: none;

      &:hover {
        color: #000;
        background: transparent;
      }
    }
  }

  &__register {
    color: #000;
    font-size: 11px;
    font-weight: 400;
    line-height: 50px;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    background-color: transparent;
    height: 50px;
    float: left;
    text-align: center;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0 55px;
    border: 1px #9ed2ee solid;
    margin-top: 43px;

    &:hover {
      color: #fff;
      background-color: $blue;
      text-decoration: none;
    }
  }
}
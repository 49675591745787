.registrace_end {
  box-shadow: 13px 22px 40px rgba(0, 0, 0, 0.1);
  background: $gray-light url("../assets/bg_end.png") top left no-repeat;
  padding: 50px 5vw 50px 15vw;
  text-align: left;

  &__heading {
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: -0.38px;
    padding-bottom: 40px;

    strong {
      font-weight: 600;
      letter-spacing: -0.38px;
    }
  }

  &__text {
    color: #888888;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.27px;
  }
}
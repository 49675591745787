.info {
  background-color: rgba(#edf2f4,0.8);
  position: relative;
  height: 540px;
  @media (max-width: 768px) {
    height: 610px;
  }

  &:before {
    position: absolute;
    background: #fff;
    width: 100%;
    height: 85px;
    left: 0;
    top: 0;
    content: "";
    z-index: 0;
  }

  .container {
    position: relative;
  }

  &__text {
    background-color: rgba($blue,0.9);
    padding: 45px;
    color: #ffffff;
    font-size: 45px;
    font-weight: 400;
    line-height: 55px;
    font-weight: 200;
    letter-spacing: -0.93px;
    position: relative;
    bottom: 240px;
    @media (max-width: 768px) {
      font-size: 25px;
      line-height: 35px;
      padding: 15px;
      bottom: 180px;
    }

    p {
      margin: 0;
    }

    img {
      width: 18px;
      height: 29px;
      margin: 70px 0 0;
      -webkit-animation: animation_image__object_bounce 5s infinite ease-in-out;
      -o-animation: animation_image__object_bounce 5s infinite ease-in-out;
      -ms-animation: animation_image__object_bounce 5s infinite ease-in-out;
      -moz-animation: animation_image__object_bounce 5s infinite ease-in-out;
      animation: animation_image__object_bounce 5s infinite ease-in-out;
      @media (max-width: 768px) {
        margin-top: 15px;
      }
      @-webkit-keyframes animation_image__object_bounce {
        0% {
          transform: translateY(0);
        }

        20% {
          transform: translateY(-15px);
        }

        50% {
          transform: translateY(-10px);
        }

        80% {
          transform: translateY(-15px);
        }

        100% {
          transform: translateY(0);
        }
      }
      @keyframes animation_image__object_bounce {
        0% {
          transform: translateY(0);
        }

        20% {
          transform: translateY(-15px);
        }

        50% {
          transform: translateY(-10px);
        }

        80% {
          transform: translateY(-15px);
        }

        100% {
          transform: translateY(0);
        }
      }
    }

    strong {
      font-weight: 600;
      letter-spacing: -0.93px;
    }
  }

  &__bottom {}

  &__image {
    width: 100%;
    background-position: center;
    background-size: cover;
    height: 530px;
    @media (max-width: 768px) {
      height: 300px;
    }
  }
}
.header {
  padding: 0;
  height: 95px;

  .container {
    position: relative;
  }

  &__logo {
    background-image: url("../assets/logo.png");
    background-repeat: no-repeat;
    width: 266px;
    height: 53px;
    position: relative;
    top: 35px;
    margin-right: 45px;
    text-indent: -9999px;
    overflow: hidden;
    display: inline-block;
    @media (max-width: 991px) {
      width: 133px;
      height: 26px;
      background-size: 100%;
    }
    @media (max-width: 768px) {
      top: 40px;
    }

    a {
      width: 100%;
      float: left;
      height: 100%;
    }
  }

  &__text {
    color: #8a9193;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    font-weight: 200;
    letter-spacing: -0.25px;
    position: relative;
    padding-left: 30px;
    display: inline-block;
    position: absolute;
    padding-top: 29px;
    @media (max-width: 768px) {
      position: absolute;
      right: 15px;
      top: 10px;
      width: 100%;
      text-align: right;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 63px;
      background-color: #d6dddf;
      width: 1px;
      @media (max-width: 768px) {
        display: none;
      }
    }

    strong {
      font-weight: 600;
      letter-spacing: -0.25px;
    }
  }

  nav {
    float: right;
    @media (max-width: 768px) {
      display: none;
    }

    ul {
      padding: 0;
      list-style: none;

      li {
        display: inline-block;
        padding: 44px 20px 0;
        position: relative;

        a {
          color: #000000;
          font-size: 13px;
          font-weight: 400;
          line-height: 25px;
          letter-spacing: -0.25px;
        }

        &:nth-child(1) {
          &:before {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            height: 63px;
            background-color: #d6dddf;
            width: 1px;
          }
        }
      }
    }
  }
}
.registrace {
  padding: 120px 0;

  &__acceptance {
    font-size: 12px;

    a {
      color: #8a9193;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__bottom {
    button {
      margin: 0 0 20px;
    }
  }

  &__padding {
    padding-top: 40px;

    table {
      width: 100%;
      margin-top: 20px;

      td:nth-child(1) {
        font-weight: 600;
      }

      td:nth-child(2) {
        text-align: right;
      }
    }
  }

  &__gallery {
    ul {
      list-style: none;
      padding: 18px 0 0;
      margin: 0;

      li {
        display: inline-block;
        margin-right: 5px;
        width: 27%;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &__under {
    color: #8a9193;
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
    width: 100%;
    text-align: center;
    margin-top: -50px;
  }

  h2 {
    color: #000000;
    font-size: 30px;
    font-weight: 400;
    line-height: 55px;
    letter-spacing: -0.57px;
    text-align: center;
    width: 100%;
    padding: 0 0 50px;
    margin: 0;
  }

  h3 {
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.38px;

    span {
      width: 30px;
      height: 30px;
      background-color: #9ed2ee;
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      position: relative;
      top: -2px;
      display: inline-block;
      text-align: center;
      margin-right: 15px;
      letter-spacing: -0.3px;
    }
  }

  &__group {
    width: 100%;
    position: relative;
    padding-left: 0;
  }

  input[type="text"],
  input[type="email"] {
    border: 1px solid rgba(169, 175, 176, 0.8);
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: -0.27px;
    height: 43px;
    line-height: 41px;
    width: 100%;
    outline: none;
    padding: 0 10px;
  }

  label.podminky {
    color: #999999;
    font-size: 13px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.25px;

    a {
      text-decoration: underline;
      color: #999999;

      &:hover {
        text-decoration: none;
      }
    }
  }

  button {
    color: #ffffff;
    font-size: 11px;
    font-weight: 400;
    line-height: 53px;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    background-color: #000000;
    height: 50px;
    text-align: center;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0 55px;
    border: 1px #000 solid;
    outline: none;
    margin-top: 30px;

    &:hover {
      color: #000;
      background: #fff;
    }
  }

  input[type="checkbox"] {
    position: relative;
    width: 27px;
    height: 27px;
    margin: 0;
    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    border: none;
    margin-right: 15px;
    top: -3px;
    outline: none;
    @extend .easing;
    vertical-align: top;

    &:focus {
      box-shadow: none;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      z-index: 1;
      width: 27px;
      height: 27px;
      border: 1px solid #a6abb7;
      border-radius: 2px;
      @extend .easing;
    }

    &:before {
      background: #fff url("../assets/ico-checkbox.png");
      background-size: 10px 10px;
      background-repeat: no-repeat;
      background-position: 8px 8px;
      position: absolute;
      left: 2px;
      z-index: 2;
      opacity: 0;
      width: 100%;
      height: 100%;
      color: #cbcbcb;
      @extend .easing;
    }

    &:checked:after {
      border: 1px solid #a6abb7;
    }

    &:checked:before {
      content: '';
      position: absolute;
      top: 0;
      opacity: 1;
      left: 0;
      border: 1px solid #a6abb7;
      border-radius: 2px;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid rgba(169, 175, 176, 0.8);
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: -0.27px;
    height: 43px;
    line-height: 41px;
    width: 100%;
    outline: none;
    padding: 0 10px;
    border-radius: 0;
    background-image: url("../assets/ico-select.png");
    background-repeat: no-repeat;
    background-size: 9px 6px;
    background-position: center right 20px;
  }

  &__label {
    color: #888888;
    font-size: 14px;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: -0.27px;
  }

  &__control {
    &--checkbox {
      position: absolute;
      left: 0;
      top: 20px;
    }
  }

  &__item {
    &_heading {
      color: #000000;
      font-size: 15px;
      font-weight: 400;
      font-weight: 600;
      letter-spacing: -0.28px;
      line-height: 15px;
      width: 100%;
      display: inline-block;
      padding-left: 28px;
      padding-bottom: 10px;
      margin-top: 20px;
    }

    &_desc {
      color: #999999;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.27px;
      line-height: 24px;
      letter-spacing: -0.27px;
      max-width: 400px;
      display: inline-block;
      padding-left: 28px;
    }
  }
}

.row--line {
  padding: 45px 0;
  position: relative;

  &:after {
    content: "";
    border-top: 1px solid #c9d2d6;
    width: calc(100% - 30px);
    position: absolute;
    left: 15px;
    top: 0;
  }
}
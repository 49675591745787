.howitworks {
  background-color: rgba(#edf2f4,0.8);
  padding: 150px 0 0;

  h2 {
    color: #000000;
    font-size: 30px;
    font-weight: 400;
    line-height: 55px;
    letter-spacing: -0.57px;
    padding-left: 45px;
    @media (max-width: 768px) {
      padding-left: 0;
    }
  }

  &__text {
    color: #999999;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: -0.38px;
    padding-left: 45px;
    @media (max-width: 768px) {
      padding-left: 0;
    }
  }

  p {
    margin: 0;
  }
}
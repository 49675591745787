.footer {
  background-color: $gray-light;
  position: relative;
  padding-top: 45px;

  &__tos {
    float: right;
    color: #8a9193;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    color: #000000;
    font-size: 15px;
    font-weight: 400;
    line-height: 28px;

    a {
      color: #000;

      &:hover {
        text-decoration: none;
      }
    }

    strong {
      font-weight: 600;
      letter-spacing: -0.28px;
    }
  }

  &__lighter {
    color: #999999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.23px;
    line-height: 45px;
  }
}

body.home {
  .footer {
    &:before {
      z-index: -1;
      left: 0;
      top: -175px;
      height: 175px;
      background-color: $gray-light;
      content: "";
      position: absolute;
      width: 100%;
    }
  }
}
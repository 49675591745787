* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: #fff;
  color: #333;
  -webkit-font-smoothing: antialiased;
  font-family: 'Libre Franklin', sans-serif;

  &.fixed {
    overflow: hidden;
  }
}

.easing {
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}

.nopadding {
  padding: 0 !important;
}

body > svg {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 1px;
}
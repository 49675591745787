.benefits {
  padding: 70px 0;

  .container {
    position: relative;
  }

  h2 {
    color: #000000;
    font-size: 30px;
    font-weight: 400;
    line-height: 55px;
    letter-spacing: -0.57px;
  }

  &__blue {
    box-shadow: 13px 22px 40px rgba(0, 0, 0, 0.1);
    background-color: $blue;
    padding: 40px 50px;
    position: relative;
    z-index: 2;
    margin-bottom: 83px;
    @media (max-width: 768px) {
      padding: 20px 25px;
      margin-bottom: 20px;
    }

    h2 {
      color: #ffffff;
      font-size: 30px;
      font-weight: 400;
      line-height: 55px;
      letter-spacing: -0.57px;
    }
  }

  &__text {
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.34px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  &__login {
    position: relative;
    z-index: 2;
    @media (max-width: 768px) {
      text-align: center;
    }

    p {
      color: #000000;
      font-size: 20px;
      font-weight: 400;
      line-height: 50px;
      letter-spacing: -0.38px;
      float: left;
      @media (max-width: 768px) {
        display: none;
      }
    }

    a {
      color: #ffffff;
      font-size: 11px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1.1px;
      background-color: #000000;
      height: 50px;
      line-height: 50px;
      padding: 0 30px;
      float: right;
      border: 1px #000 solid;
      @media (max-width: 768px) {
        width: 100%;
      }

      &:hover {
        color: #000;
        text-decoration: none;
        background: #fff;
      }
    }
  }

  &__image {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -50px;
    height: 380px;
    background: url("../assets/bg_benefits.jpg");
    background-color: rgba(#edf2f4,0.8);
    background-position: left center;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
}
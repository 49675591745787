.points {
  text-align: center;
  background-color: rgba(#edf2f4,0.8);
  padding: 120px 0;
  @media (max-width: 768px) {
    padding: 60px 0;
  }

  .col-md-4 {
    position: relative;

    &:before {
      width: 100%;
      height: 65px;
      background-image: linear-gradient(to right, #ffffff 0%, #f4f7f8 100%);
      position: absolute;
      left: 0;
      top: 35px;
      content: "";
      z-index: 0;
    }

    &:nth-child(2):before {
      background-image: linear-gradient(to right, #f4f7f8 0%, #ffffff 100%);
    }
  }

  &__image {
    height: 80px;
    position: relative;
    z-index: 1;

    img {
      max-height: 100%;
      width: auto;
    }
  }

  h2 {
    color: #000000;
    font-size: 30px;
    font-weight: 400;
    line-height: 55px;
    letter-spacing: -0.57px;
    position: relative;
    z-index: 2;
  }

  &__text {
    color: #999999;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.28px;
    max-width: 250px;
    display: inline-block;
    position: relative;
    z-index: 2;
  }
}
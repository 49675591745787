.loginbox {
  background: #fff;
  position: relative;
  bottom: 420px;
  @media (max-width: 768px) {
    bottom: 180px;
  }

  &__heading {
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.38px;
    padding: 40px 0 20px 50px;
    @media (max-width: 768px) {
      padding: 20px 0 20px 25px;
    }
  }

  &__password {
    color: #000000;
    font-size: 12px;
    font-weight: 400;
    line-height: 50px;
    text-decoration: underline;
    letter-spacing: -0.23px;
    float: right;
    @media (max-width: 768px) {
      position: relative;
      top: -30px;
    }
  }

  &__register {
    color: #000000;
    font-size: 11px;
    font-weight: 400;
    line-height: 55px;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    position: absolute;
    right: 50px;
    bottom: 13px;
    @media (max-width: 768px) {
      right: 5px;
    }

    img {
      width: 18px;
      position: relative;
      top: -1px;
      margin-left: 10px;
      height: 11px;
    }
  }

  p {
    color: $blue;
    font-size: 15px;
    font-weight: 400;
    line-height: 15px;
    padding-left: 50px;
    letter-spacing: -0.28px;
    padding: 15px 0 35px 50px;
    @media (max-width: 768px) {
      padding: 15px 0 35px 25px;
    }
  }

  form {
    padding: 0 50px;
    @media (max-width: 768px) {
      padding: 0 25px;
    }

    input[type="email"],
    input[type="password"] {
      background-color: $gray-light;
      outline: none;
      color: #999999;
      font-size: 15px;
      font-weight: 400;
      line-height: 51px;
      letter-spacing: -0.28px;
      height: 51px;
      padding: 0 10px;
      margin-bottom: 8px;
      width: 100%;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
    }

    button {
      color: #ffffff;
      font-size: 11px;
      font-weight: 400;
      line-height: 53px;
      text-transform: uppercase;
      letter-spacing: 1.1px;
      background-color: #000000;
      height: 50px;
      text-align: center;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 0 55px;
      border: 1px #000 solid;
      outline: none;
      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
      }

      &:hover {
        color: #000;
        background: #fff;
      }
    }
  }
}